import React,{ useState,useEffect } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion"
import "@reach/accordion/styles.css"

const Faqs = ({ faq }) => {
  const [showDouble, setShowDouble] = useState(true);
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [showFilter,setShowFilter] = useState(false)


  const updatePosts=(e)=>{
    if(url==="/personal-savings/"){
      setShowFilter(true)
    }

    if(faq[0].faqs.nodes.length===4){
      setShowDouble(false)

    }
  }
  useEffect(() => {
    updatePosts()
  }, []);

  return (
    <>
      <section className="content very-light-blue-background">
        <div className="eighty-spacer"></div>
        <div className="content-block">
          <div className="centered-title-holder">
            <h1 className="staggerFadeInFirst first-load">
              Frequently Asked Questions
            </h1>
          </div>
          <div className="scroll-to-filter"></div>
          <div className="filter-block"></div>
          <div className="faq-list-container">
            {faq.length
              ? faq.map((cat,index) => (
                  <div key={index}>
                    <ul className="faqs-mixit-container" id="MixItUp65659F">
                    <Accordion collapsible>
                      {cat.faqs.nodes.map((faq, index) => (
                        <li className="mix personal-savings " key={index}>

                              <AccordionItem className="mix personal-savings">
                                <div className="c-faq-question-holder">
                                  <AccordionButton className="question-text">
                                    {faq.childFaqs.nodes[0].title}
                                    <span className="white-mango-status-icon"></span>
                                  </AccordionButton>
                                </div>
                                <AccordionPanel className="c-faq-answer-container">
                                  <div className="c-faq-answer-holder" dangerouslySetInnerHTML={{ __html: faq.childFaqs.nodes[0].content }}/>
                                </AccordionPanel>
                              </AccordionItem>

                            {showDouble ? (

                              <AccordionItem className="mix personal-savings">
                                <div className="c-faq-question-holder">
                                  <AccordionButton className="question-text">
                                    {faq.childFaqs.nodes[1].title}
                                    <span className="white-mango-status-icon"></span>
                                  </AccordionButton>
                                </div>
                                <AccordionPanel className="c-faq-answer-container">
                                  <div className="c-faq-answer-holder" dangerouslySetInnerHTML={{ __html: faq.childFaqs.nodes[1].content }}/>
                                </AccordionPanel>
                              </AccordionItem>

                          ):null}
                        </li>
                      ))}
                      </Accordion>
                    </ul>
                  </div>
                ))
              : null}
                {showFilter ? (
              <div className="all-faqs-link staggerFadeIn stag-visible"><a href="/faqs/" data-link="personal-savings">All Savings FAQs</a>
              </div>
              ):
              <div className="all-faqs-link staggerFadeIn stag-visible"><a href="/business/faqs-business/" data-link="savings">All Savings FAQs</a>
              </div>
            }
          </div>
        </div>
        <div className="eighty-spacer"></div>
      </section>
    </>
  )
}

export default Faqs
